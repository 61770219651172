import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';

const FaqPage: FunctionComponent<any> = ({ data: { contentfulFaqPage } }) => {
  return (
    <CmsContext.Provider value={contentfulFaqPage}>
      <Seo
        title={contentfulFaqPage.metaTags.metatitle}
        description={contentfulFaqPage.metaTags.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default FaqPage;

export const query = graphql`
  query FAQPage {
    contentfulFaqPage {
      metaTags {
        metatitle
        metadescription
      }
      title
      subTitle
      faqInstitut {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
      faqGebeNachhilfe {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
      faqSucheNachhilfe {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
      faqGebeNachhilfeElite {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
      faqSucheNachhilfeElite {
        ... on ContentfulFaq {
          quoteAuthor
          content {
            content
          }
        }
      }
    }
  }
`;
